import { graphql } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";
import { SEO } from "../components/SEO";
import { PageProps } from "../types/global";
import { navigate } from 'gatsby-link';
import { SiMinutemailer, SiCoffeescript, SiFacebook, SiLinkedin, SiInstagram, SiWhatsapp, SiTwitter, SiGithub, SiGitlab, SiDiscord, SiTelegram, SiGmail } from "react-icons/si";
import { RiUser3Line } from "react-icons/ri";
import { BsChatText, BsWhatsapp } from "react-icons/bs";
import { GiPlanetConquest } from "react-icons/gi";
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha";
import { MyMap } from "~/components/myMap";
import ContactDetail from "~/components/contactDetail";
import contact from "~/assets/background/contact.jpeg";
import video from "../assets/sea.mp4";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            
          }
    }
`;
const GetInTouch = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5rem;
`;
const Message = styled.p`
    a {
        padding: 0.5rem;
        width: 1rem;
        height: 1rem;
        text-decoration: none;
        color: #fff;
    }
`;
const MainText = styled.p`
    display: table-row;
`;
const MainTextCenter = styled(MainText)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 1rem;
`;
const Center= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Label = styled.label`
    display: table-cell;
    font-size: 1.2em;
    color: #fff;
     font-family: 'Mukta', sans-serif;
    font-weight: 400;
    margin: 0;
    text-align: left;
    padding: 1rem;
`;
const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    height: 100%;
    overflow: hidden;
    background: transparent;
    background-size: contain;
    position: relative;
`;
const Video = styled.video`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
    transition: all 2s;
    margin: 0 auto;
`;

const Form = styled.form`
    display: table;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    padding: 50px;
    margin-bottom: 10rem;
`;

const Input = styled.input`
    background: transparent;
    width: 100%;
    clear: both;
    color: #fff;
    display: table-cell;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 15px;
    background-color: transparent;
    font-family: 'Mukta', sans-serif;
`;
const TextArea = styled.textarea`
    background: transparent;
    width: 100%;
    clear: both;
    color: #fff;
    display: table-cell;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 15px;
    background-color: transparent;
    font-family: 'Mukta', sans-serif;
`;

const Button = styled.button`
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    padding: 0.2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    color: #fff;
     font-family: 'Mukta', sans-serif;
    font-weight: 400;
`;

const ContactUS = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2.5em;
    color: #797979;
    font-family: 'Mukta', sans-serif;
    font-weight: 400;
    padding: 5rem 0 1rem 0;
`;
const SiCoffeescriptWhite = styled(GiPlanetConquest)`
    color: #fffddd;
    background: transparent;
    width: 3rem;
    height: 3rem;
    margin: 0 0 1rem 0;
`;
const SiFacebookSize = styled(SiFacebook)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 0.3s all;
    color: #0077ff;
`;
const SiInstagraSize = styled(SiInstagram)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    color: #d6249f;
`;
export const SiWhatsappSize = styled(SiWhatsapp)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    color: #27a32e;
`;
const SiTwitterSize = styled(SiTwitter)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    color: #00c3ff;
`;

const SiLinkedinSize = styled(SiLinkedin)`
@media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    color: #044fb1;
`;

const SiGmailSize = styled(SiGmail)`
    @media (max-width: 700px) {
        width: 1rem;
        height: 1rem;
    }
    width: 2rem;
    height: 2rem;
    transition: 1s all;
    color: red;
`;
function encode(data: { [x: string]: string | number | boolean; "form-name"?: any; }) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  
const ContactPage: React.FunctionComponent<PageProps> = (props)=> {
    const [state, setState] = React.useState({})

  const handleChange = (e:any) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e:any) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  } 
    const [submitted, setSubmitted] = useState(false)
    const submit = (token: any) => {}

    return (
    <>
    
    <SEO
                lang={props.pageContext.language}
                title="Contact Me"
                description="Wanderlust Tourisms India"
                keywords={["Wanderlust Tourisms India"]}
                url={props.location.pathname}
            />
    <Container>
    <Video loop autoPlay muted playsInline >
            <source src={video} type="video/mp4"/>
    </Video>
        <ContactUS>Get in touch</ContactUS>
        <Form 
            name="Contact-form"
            method="post"
            action="/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
                <label>Get in touch with me <input name="bot-field" onChange={handleChange} />
                </label>
            </p>
            <MainText>
                <Label>Full Name: <RiUser3Line/><Input type="text" name="name" onChange={handleChange}/></Label>   
            </MainText>
            <MainText>
                <Label>Whatsapp Number: <BsWhatsapp/><Input type="tel" name="whatsapp" onChange={handleChange}/></Label>
            </MainText>
            <MainText>
                <Label>Email ID: <SiMinutemailer/><Input type="email" name="email" onChange={handleChange}/></Label>
            </MainText>
            <MainText>
                <Label>Text me: <BsChatText/><TextArea name="message" onChange={handleChange}></TextArea></Label>
            </MainText>
            <MainTextCenter>
                <Button data-action='submit' onClick={() => setSubmitted(true)} type="submit">Send</Button>
            </MainTextCenter>
            <Center>
                <ReCaptcha
                action="submit"
                siteKey="6LdK808gAAAAAExb8STx1Xgn75nUV8SOHyByBdSv"
                onVerify={(token:any) => submit(token)}
                submitted={submitted}
                size="invisible"
            />
            </Center>
            <GetInTouch>
            <Message>
                <a href="https://www.facebook.com/wanderlust.tourismsindia/"><SiFacebookSize/></a>
                <a href="https://instagram.com/wanderlusttourism.in?igshid=YmMyMTA2M2Y="><SiInstagraSize/></a>
                <a href="https://wa.me/+919691111998"><SiWhatsappSize/></a>
                <a href="https://twitter.com/wanderlustindi?t=TaN5TgYlsZIVdVTjZuZRug&amp;s=09"><SiTwitterSize/></a>
                <a href="https://www.linkedin.com/company/wanderlust-tourism-india/"><SiLinkedinSize/></a>
                <a href="mailto: sales@wanderlusttourism.in"><SiGmailSize/></a>
            </Message>
        </GetInTouch>
        </Form>
    </Container>
    <MyMap/>
    <ContactDetail/>
    </>
    )
}

export default ContactPage
